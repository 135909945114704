/*---------------------------------------------------------------------------------------------
*
*  Field picker modal
*
*---------------------------------------------------------------------------------------------*/
.acf-modal.acf-browse-fields-modal {
	width: 1120px;
	height: 664px;
	top: 50%;
	right: auto;
	bottom: auto;
	left: 50%;
	transform: translate(-50%, -50%);
	display: flex;
	flex-direction: row;
	border-radius: $radius-xl;
	box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.04),
		0px 8px 16px rgba(0, 0, 0, 0.08);
	overflow: hidden;

	.acf-field-picker {
		display: flex;
		flex-direction: column;
		flex-grow: 1;
		width: 760px;
		background: #fff;

		.acf-modal-title,
		.acf-modal-content,
		.acf-modal-toolbar {
			position: relative;
		}

		.acf-modal-title {
			display: flex;
			flex-direction: row;
			justify-content: space-between;
			align-items: center;
			background: $gray-50;
			border: none;
			padding: 35px 32px;

			.acf-search-field-types-wrap {
				position: relative;

				&:after {
					content: "";
					display: block;
					position: absolute;
					top: 11px;
					left: 10px;
					$icon-size: 18px;
					width: $icon-size;
					height: $icon-size;
					-webkit-mask-image: url("../../images/icons/icon-search.svg");
					mask-image: url("../../images/icons/icon-search.svg");
					background-color: $gray-400;
					border: none;
					border-radius: 0;
					-webkit-mask-size: contain;
					mask-size: contain;
					-webkit-mask-repeat: no-repeat;
					mask-repeat: no-repeat;
					-webkit-mask-position: center;
					mask-position: center;
					text-indent: 500%;
					white-space: nowrap;
					overflow: hidden;
				}

				input {
					width: 280px;
					height: 40px;
					margin: 0;
					padding-left: 32px;
					box-shadow: none;
				}
			}
		}

		.acf-modal-content {
			top: auto;
			bottom: auto;
			padding: 0;
			height: 100%;

			.acf-tab-group {
				padding-left: 32px;
			}

			.acf-field-types-tab {
				display: flex;
			}

			.acf-field-types-tab,
			.acf-field-type-search-results {
				flex-direction: row;
				flex-wrap: wrap;
				gap: 24px;
				padding: 32px;

				.acf-field-type {
					position: relative;
					display: flex;
					flex-direction: column;
					justify-content: center;
					align-items: center;
					isolation: isolate;
					width: 120px;
					height: 120px;
					background: $gray-50;
					border: 1px solid $gray-200;
					border-radius: 8px;
					box-sizing: border-box;
					color: $gray-800;
					text-decoration: none;

					&:hover,
					&:active,
					&.selected {
						background: $blue-50;
						border: 1px solid $blue-400;
						box-shadow: inset 0 0 0 1px $blue-400;
					}

					.field-type-icon {
						border: none;
						background: none;
						top: 0;

						&:before {
							width: 22px;
							height: 22px;
						}
					}

					.field-type-label {
						margin-top: 12px;
						@extend .p5;
					}
				}

				.field-type-requires-pro {
					display: flex;
					justify-content: center;
					align-items: center;
					position: absolute;
					top: -10px;
					right: -10px;
					height: 21px;
					color: white;
					background: $gradient-pro;
					background-size: 140% 20%;
					background-position: 100% 0;
					border-radius: 100px;
					font-size: 11px;
					padding: {
						right: 6px;
						left: 6px;
					}
					i {
						width: 12px;
						height: 12px;
						margin-right: 2px;
					}
				}
			}
		}

		.acf-modal-toolbar {
			display: flex;
			align-items: flex-start;
			justify-content: space-between;
			height: auto;
			min-height: 72px;
			padding: {
				top: 0;
				right: 32px;
				bottom: 0;
				left: 32px;
			}
			margin: 0;
			border: none;

			.acf-select-field,
			.acf-btn-pro {
				min-width: 160px;
				justify-content: center;
			}

			.acf-insert-field-label {
				min-width: 280px;
				box-shadow: none;
			}

			.acf-field-picker-actions {
				display: flex;
				gap: 8px;
			}
		}
	}

	.acf-field-type-preview {
		display: flex;
		flex-direction: column;
		width: 360px;
		background-color: $gray-50;
		background-image: url("../../images/field-preview-grid.png");
		background-size: 740px;
		background-repeat: no-repeat;
		background-position: center bottom;
		border-left: 1px solid $gray-200;
		box-sizing: border-box;
		padding: 32px;

		.field-type-desc {
			margin: 0;
			padding: 0;
			color: $gray-500;
		}

		.field-type-preview-container {
			display: inline-flex;
			justify-content: center;
			width: 100%;
			margin: {
				top: 24px;
			}
			padding: {
				top: 32px;
				bottom: 32px;
			}
			background-color: rgba(#fff, 0.64);
			border-radius: $radius-lg;
			box-shadow: 0px 0px 0px 1px rgba(0, 0, 0, 0.04),
				0px 8px 24px rgba(0, 0, 0, 0.04);
		}

		.field-type-image {
			max-width: 232px;
		}

		.field-type-info {
			flex-grow: 1;

			.field-type-name {
				font-size: 21px;
				margin: {
					top: 0;
					right: 0;
					bottom: 16px;
					left: 0;
				}
			}

			.field-type-upgrade-to-unlock {
				display: inline-flex;
				justify-items: center;
				align-items: center;
				min-height: 24px;
				margin: {
					bottom: 12px;
				}
				padding: {
					right: 8px;
					left: 8px;
				}
				background: $gradient-pro;
				background-size: 140% 20%;
				background-position: 100% 0;
				border-radius: 100px;
				color: white;
				text-decoration: none;
				font-size: 11px;

				i.acf-icon {
					width: 14px;
					height: 14px;
					margin: {
						right: 4px;
					}
				}
			}
		}

		.field-type-links {
			display: flex;
			align-items: center;
			gap: 24px;
			min-height: 40px;

			.acf-icon {
				$icon-size: 18px;
				width: $icon-size;
				height: $icon-size;
			}

			&:before {
				display: none;
			}

			a {
				display: flex;
				gap: 6px;
				text-decoration: none;

				&:hover {
					text-decoration: underline;
				}
			}
		}
	}

	.acf-field-type-search-results,
	.acf-field-type-search-no-results {
		display: none;
	}

	&.is-searching {
		.acf-tab-wrap,
		.acf-field-types-tab,
		.acf-field-type-search-no-results {
			display: none !important;
		}

		.acf-field-type-search-results {
			display: flex;
		}
	}

	&.no-results-found {
		.acf-tab-wrap,
		.acf-field-types-tab,
		.acf-field-type-search-results,
		.field-type-info,
		.field-type-links,
		.acf-field-picker-toolbar {
			display: none !important;
		}

		.acf-modal-title {
			border-bottom: {
				width: 1px;
				style: solid;
				color: $gray-200;
			}
		}

		.acf-field-type-search-no-results {
			display: flex;
			flex-direction: column;
			justify-content: center;
			align-items: center;
			height: 100%;
			gap: 6px;

			img {
				margin-bottom: 19px;
			}

			p {
				margin: 0;

				&.acf-no-results-text {
					display: flex;
				}
			}

			.acf-invalid-search-term {
				max-width: 200px;
				overflow: hidden;
				text-overflow: ellipsis;
				display: inline-block;
			}
		}
	}
}

/*---------------------------------------------------------------------------------------------
*
*  Hide browse fields button for smaller screen sizes
*
*---------------------------------------------------------------------------------------------*/
@media only screen and (max-width: 1080px) {
	.acf-btn.browse-fields {
		display: none;
	}
}
